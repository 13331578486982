// Some custom colors
$link-color: hsl(165, 82%, 51%); // Main Color
$text-color: #263238; //Material Black
$light-black: #424242;
$off-white: #e0e1e0;
$button-highlight: #a6bacb;
$main-color: #1de9b6;
$black: #192824;
$green: #03dac6;
$yellow: #ffd913;
$orange: #ff9c55;
$red: #ff5555;
$vlight-green: #a4ffe6;
$red: #ff5555;
$p-height: 10px;
$white: #fff;
$red: #e65f61;
$muted-red: #f26c67;
$pink: #fa7c91;
$brown: #a1887f;
$beige-light: #e9e9e8;
$beige-lighter: #eff0eb;
$beige: #e4e5e2;
$blueish: #8bd0c3;
$grey: #353f2d;
$skyblue: #080808;
$blue-1: #8cd1c3;
$purple: #bb86fc;
$green: #9fbf87;

//Brand
$light-blue: #cef5ed;
$dark-blue: #8acfc1;
$blue: #9ee2d4;
$skyblue: #42a1ff;
//Custom colors
$primary: $orange;
$light: $blue-1;
$info: $black;
$link: $dark-blue;
$success: $vlight-green;
$warning: $green;
$danger: $muted-red;
// Update Bulma's global variables
//@extend
//@extend
//Fonts
$family-sans-serif: 'Overpass', sans-serif;
$family-secondary: 'Roboto', sans-serif;
$family-primary: $family-sans-serif;

$grey-dark: $text-color;
$grey-light: $beige;
$weight-normal: 300;
$weight-light: 200;
$navbar-height: 2.5rem;
$navbar-padding-vertical: 5rem;
$navbar-dropdown-background-color: lighten($off-white, 30%);
$dropdown-content-background-color: transparentize($white, 0.6);
$dropdown-content-padding-top: 0px;
$dropdown-content-padding-bottom: 0px;
//Other props
$widescreen-enabled: false;
$fullhd-enabled: false;
$footer-padding: 2rem 1.5rem 2rem 1.5rem;

// Update some of Bulma's component variables
$body-background-color: lighten(#f2f2f2, 10%);
$navbar-item-hover-color: darken(#000, 20%);
$control-border-width: 2px;
$box-background-color: lighten($link, 20%);
$card-background-color: darken($white, 0%);
$footer-background-color: $light-black;
$border-light: $light-black;
$progress-bar-background-color: transparentize($light-black, 0.6);
$text: $white;
$family-code: monospace;
$input-background-color: transparent;
$pagination-border-color: transparentize($black, 0.7);
$navbar-tab-hover-border-bottom-color: $text-color;
$navbar-item-hover-background-color: transparent;
$menu-item-active-background-color: lighten($link, 10%);
$menu-item-hover-background-color: transparent;
//Size override
$table-cell-padding: 0.15em 0.25em;
$size-1: 8rem;
$size-2: 5rem;
$size-3: 2.5rem;
$size-4: 1.5rem;
$size-5: 1.25rem;
$size-6: 1rem;
$size-7: 0.8rem;
$dimensions: 16 24 32 48 64 96 128 256 512;
$modal-content-width: 1024px;
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.initialLoader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 10em;
  height: 10em;
  border: 0.2em solid #fff;
  border-left: 0.2em solid $primary;
  border-radius: 50%;
  animation: load8 1s infinite linear;
  transition: opacity 1s;
}

.initialLoader--hide {
  z-index: -99999999;
  opacity: 0;
}

.blur-bg {
  z-index: 99999999;
  transition: opacity 1s;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparentize($color: #000000, $amount: 0.2);
  backdrop-filter: blur(1.25rem);
}
.blur-bg--hide {
  z-index: -99999999;
  opacity: 0;
  width: 0;
  height: 0;
}
html {
  overflow-y: auto !important;
  user-select: none;
}
::-webkit-scrollbar {
  width: 5px !important;
}

::-webkit-scrollbar-track {
  background: #ddd !important;
}

::-webkit-scrollbar-thumb {
  background: #666 !important;
}

.hide-root {
  overflow-y: hidden;
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.sk-cube-grid {
  width: 80px;
  height: 80px;
  margin: 250px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #fff;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

.image--loading {
  &::after {
    animation: shine 1s cubic-bezier(0.6, -0.28, 0.735, 0.045) infinite;

    animation-fill-mode: forwards;
    content: '';
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0.6;
    transform: rotate(10deg);

    background: rgba(255, 255, 255, 0.4);
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.3) 92%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

@keyframes shine {
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}

// Import only what you need from Bulma
// @import '~bulma/bulma.sass';
@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/base/_all.sass';
@import '~bulma/sass/elements/container.sass';
@import '~bulma/sass/elements/title.sass';
@import '~bulma/sass/elements/content.sass';
@import '~bulma/sass/elements/image.sass';
@import '~bulma/sass/elements/notification.sass';
@import '~bulma/sass/elements/table.sass';
@import '~bulma/sass/elements/tag.sass';
@import '~bulma/sass/elements/progress.sass';
@import '~bulma/sass/elements/icon.sass';
@import '~bulma/sass/elements/box.sass';
@import '~bulma/sass/elements/other.sass';
@import '~bulma/sass/elements/button.sass';
@import '~bulma/sass/components/card.sass';
@import '~bulma/sass/components/level.sass';
@import '~bulma/sass/components/tabs.sass';
@import '~bulma/sass/components/menu.sass';
@import '~bulma/sass/components/dropdown.sass';
@import '~bulma/sass/components/pagination.sass';
@import '~bulma/sass/components/message.sass';
@import '~bulma/sass/components/modal.sass';
@import '~bulma/sass/components/media.sass';
@import '~bulma/sass/components/panel.sass';
@import '~bulma/sass/grid/columns.sass';
@import '~bulma/sass/grid/tiles.sass';
@import '~bulma/sass/helpers/_all.sass';
@import '~bulma/sass/layout/_all.sass';

@import '~bulma/sass/form/_all.sass';
@import '~bulma/sass/components/navbar.sass';

//Ahhhhh We dont want this
$card-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
.table td,
.table th {
  border-color: $link-color;
}

#content-main {
  background-color: lighten($color: $beige-light, $amount: 8%);
  padding: 5vmax;
}
.table {
  background-color: initial;
}

.content table td,
.content table th {
  border: none;
}
.level-left + .level-right {
  margin-top: 0.2rem;
}
.level:not(:last-child) {
  margin-bottom: 0.8rem;
}
.message:not(:last-child) {
  margin-bottom: 0rem !important;
}
//Custom functions
$colorsPal: (
  light: $light,
  primary: $primary,
  link: $link,
  info: $info,
  success: $success,
  warning: $warning,
  danger: $danger,
  white: $white,
  black: $black,
);
@each $colorname, $color-bg in $colorsPal {
  .is-muted-#{$colorname} {
    background-color: lighten($color: $color-bg, $amount: 25%) !important;
  }
}
@each $colorname, $color-bg in $colorsPal {
  .is-dark-#{$colorname} {
    background-color: darken($color: $color-bg, $amount: 15%) !important;
  }
}
.is-glass-link {
  backdrop-filter: blur(1em) opacity(0.7) brightness(120%);
  background-color: transparentize(#cef5ed, 0.2) !important;
}
// Some custom SASS
.is-hyperlink {
  cursor: pointer;
}
.is-hcentered {
  justify-content: center;
}
.is-spaced-bw {
  justify-content: space-between;
}
.is-vcentered {
  align-items: center;
}
.hr-match {
  display: inline-block;
  &:after {
    content: '';
    display: block;
    border-top: 5px solid $link-color;
    transform: skewX(-50deg);
    margin-right: 10px;
  }
}
.slick-prev,
.slick-next {
  &::before {
    color: #3c3c3b !important;
  }
}

.has-no-bg {
  background: transparent !important;
}
.is-active-page {
  background-color: lighten($link-color, 30%);
}
.fit-desktop {
  @media screen and (min-width: 1024px) {
    max-width: 1024px !important;
  }
}
